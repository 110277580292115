import React, { Component } from "react";
import WeekCard from "./WeekCard";
import { connect } from "react-redux";
import { Result, Spin } from "antd";
import { withFirebase } from "../../../../firebase";
import "../Dashboard.css";
import { getInboxProjects } from "../../../../redux/actions";

class Inbox extends React.Component {
  componentDidMount() {
    this.props.getInboxProjects(this.props.firebase, this.props.user.uid);
  }

  render() {
    return (
      <div>
        {this.props.fetchingInbox ? (
          <div className="weekcard">
            <Spin size="large" style={{ display: "block", margin: "0 auto" }} />
          </div>
        ) : this.props.inbox && Object.keys(this.props.inbox).length > 0 ? (
          Object.keys(this.props.inbox).map((dateGroup) => (
            <WeekCard
              key={dateGroup}
              card={this.props.inbox[dateGroup]}
              objKey={dateGroup}
            ></WeekCard>
          ))
        ) : (
          <div className="weekcard">
            <Result
              status="success"
              title="У вас нет входящих!"
              subTitle="Форма для отчета отправляется каждый четверг в 10:00, если выбран отчет по неделям, иначе каждый рабочий день в 16:00"
            />
            ,
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  inbox: state.inbox,
  fetchingInbox: state.fetchingInbox,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getInboxProjects: (firebase, userId) =>
      dispatch(getInboxProjects(firebase, userId)),
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(Inbox)
);
