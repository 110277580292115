import React from "react";
import {
  Typography,
  Button,
  Select,
  Switch,
  Tooltip,
  Form,
  Collapse,
  InputNumber,
  DatePicker,
} from "antd";
import Slider from "./CustomSlider";
import { connect } from "react-redux";
import { postToWaiting } from "../../../../redux/actions";
import { withFirebase } from "../../../../firebase/index";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const { RangePicker } = DatePicker;

class WeekCard extends React.Component {
  constructor(props) {
    super(props);
    let formObj = {}
    this.state = { form: formObj, sliderValue: [0, 50, 100], defaultStagesNeedToSet: true};
    this.SliderReference = React.createRef();
  }

  getTitle = (project, type) => {
    const { timestamp, formSubmition } = project;
    if (formSubmition == "weekly") {
      const rightRange = new Date(timestamp + 24 * 60 * 60 * 1000)
        .toLocaleDateString("ru")
        .split("/")
        .join(".");
      const leftRange = new Date(timestamp - 6 * 24 * 60 * 60 * 1000)
        .toLocaleDateString("ru")
        .split("/")
        .join(".");
      return type === "title"
        ? `Неделя ${leftRange} - ${rightRange}`
        : [leftRange, rightRange];
    } else {
      const rightRange = new Date(timestamp)
        .toLocaleDateString("ru")
        .split("/")
        .join(".");
      const leftRange = new Date(timestamp - 24 * 60 * 60 * 1000)
        .toLocaleDateString("ru")
        .split("/")
        .join(".");
      return type === "title" ? `День ${rightRange}` : [rightRange, rightRange];
    }
  };

  getSubtitle = (project) => {
    const { formSubmition } = project;
    if (formSubmition == "weekly")
      return "Сколько времени вы уделили каждому проекту на этой неделе?";
    else {
      return "Сколько времени вы сегодня уделили каждому проекту?";
    }
  };

  formChanged = (changedValues, allValues) => {
    if(this.state.defaultStagesNeedToSet){
      this.props.card.forEach((project) => {
        allValues[project.projectData.id + '-select'] = project.projectData.stages[0].order;
      });
    }
    this.setState({ form: allValues, defaultStagesNeedToSet: false});
  };

  coloredSwitch = (project) => {
    const formPath = project.id + "-switch";
    if (this.state.form && this.state.form[formPath]) {
      return { backgroundColor: `${project.color}` };
    }
    return {};
  };

  setSelectDisabled = (projectId) => {
    if (this.state.form === null) return true;
    else {
      if (this.state.form[projectId + "-switch"]) {
        return false;
      }
      return true;
    }
  };

  submitForm = (values, selectedProjects) => {
    const sliderValues = this.SliderReference.current.state.sliderValue;
    const card = this.props.card.filter((el) =>
      selectedProjects.find((prj) => prj.id === el.projectData.id)
    );
    if (sliderValues.length > 2) {
      const projectValues = sliderValues.slice(1);
      const selectedProjectsWithValues = selectedProjects.map((prj, ind) => {
        if (ind == 0) {
          card[ind].value = projectValues[ind];
        } else {
          card[ind].value = projectValues[ind] - projectValues[ind - 1];
        }
        card[ind].selectedStage = prj.stages.filter(
          (stage) => stage.order === values[prj.id + "-select"]
        )[0].name;
        if (values.offworkDates && values.offworkDates[0])
          card[ind].offworkDateLeft = values.offworkDates[0].format(
            "DD-MM-YYYY"
          );
        if (values.offworkDates && values.offworkDates[1])
          card[ind].offworkDateRight = values.offworkDates[1].format(
            "DD-MM-YYYY"
          );
        if (values.offworkReason)
          card[ind].offworkReason = values.offworkReason;
        card[ind].formDateLeft = this.getTitle(card[ind], "array")[0];
        card[ind].formDateRight = this.getTitle(card[ind], "array")[1];
        return prj;
      });
    } else {
      const result = card[0];
      result.value = 100;
      result.selectedStage = result.projectData.stages.filter(
        (stage) => stage.order === values[result.projectData.id + "-select"]
      )[0].name;
      if (values.overwork) result.overwork = values.overwork;
      if (values.offworkDates && values.offworkDates[0])
        result.offworkDateLeft = values.offworkDates[0].format("DD-MM-YYYY");
      if (values.offworkDates && values.offworkDates[1])
        result.offworkDateRight = values.offworkDates[1].format("DD-MM-YYYY");
      if (values.offworkReason) result.offworkReason = values.offworkReason;
      result.formDateLeft = this.getTitle(result, "array")[0];
      result.formDateRight = this.getTitle(result, "array")[1];
    }

    this.props.postToWaiting(
      this.props.firebase,
      card.filter((el) => el.value),
      this.props.user.uid,
      this.props.objKey
    );
  };

  render() {
    const selectedProjects = [];
    this.props.card.forEach((project) => {
      if (
        this.state.form &&
        this.state.form[project.projectData.id + "-switch"] &&
        this.state.form[project.projectData.id + "-select"]
      ) {
        selectedProjects.push(project.projectData);
      }
    });
    return (
      <div className="weekcard">
        <Title className="weekcard-titile" level={3}>
          {this.getTitle(this.props.card[0], "title")}
        </Title>
        <Title className="weekcard-subtitile" level={4}>
          {this.getSubtitle(this.props.card[0])}
        </Title>
        <Form
          layout="vertical"
          onValuesChange={(a, b) => this.formChanged(a, b)}
          onFinish={(values) => this.submitForm(values, selectedProjects)}
        >
          <Slider
            ref={this.SliderReference}
            selectedProjects={selectedProjects}
          ></Slider>
          <div className="projects">
            {this.props.card &&
              this.props.card.map((project) => (
                <div className="project-selectors">
                  <Text className="project-item project-item-fixed-width">
                    {project.projectData.name}
                  </Text>
                  <Tooltip title={project.projectData.description}>
                    <InfoCircleOutlined className="project-item" />
                  </Tooltip>
                  <Form.Item
                    name={project.projectData.id + "-switch"}
                    noStyle={true}
                  >
                    <Switch
                      className="project-item"
                      style={this.coloredSwitch(project.projectData)}
                    ></Switch>
                  </Form.Item>
                  <Form.Item
                    name={project.projectData.id + "-select"}
                    noStyle={true}
                  >
                    <Select
                      placeholder="Выберите этап"
                      className="project-item project-select"
                      disabled={this.setSelectDisabled(project.projectData.id)}
                      defaultValue={project.projectData.stages[0].order}
                    >
                      {project.projectData.stages.map((stage) => (
                        <Option value={stage.order}>{stage.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              ))}
            <Collapse ghost className="project-additional">
              <Panel header="Если отсутствовали, отметьте причину" key="1">
                <Form.Item name="offworkDates" noStyle={true}>
                  <RangePicker />
                </Form.Item>
                <Form.Item name="offworkReason" noStyle={true}>
                  <Select
                    style={{ width: "200px" }}
                    placeholder="Выберите причину"
                  >
                    <Option value="illness">Больничный</Option>
                    <Option value="vacation">Ежегодный отпуск</Option>
                    <Option value="nonPaid">Отпуск без сохранения ЗП</Option>
                    <Option value="remote">Дистанционная работа</Option>
                    <Option value="assignment">Командировка</Option>
                  </Select>
                </Form.Item>
              </Panel>
            </Collapse>
          </div>
          <Form.Item noStyle={true}>
            <Button
              type="primary"
              htmlType="submit"
              className="weekcard-button"
            >
              Готово
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postToWaiting: (firebase, card, uid, objKey) =>
      dispatch(postToWaiting(firebase, card, uid, objKey)),
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(WeekCard)
);     