import React, { Fragment } from "react";
import { connect } from "react-redux";
import Personal from "../Profile/Personal";
import History from "../Profile/History";
import { Typography, Skeleton } from "antd";
import {
  UserOutlined,
  ProfileOutlined,
  HistoryOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { withFirebase } from "../../../../firebase";
import { getUserData, uploadAvatar } from "../../../../redux/actions";

const { Text, Title } = Typography;

class Profile extends React.Component {
  state = { selectedBlock: "info" };

  componentDidMount() {
    if (this.props.customUid)
      this.props.getUserData(this.props.firebase, this.props.customUid, 'private');
    else this.props.getUserData(this.props.firebase, this.props.user.uid, 'owner');
  }

  render() {
    return (
      <div>
        <div className="profile-select">
          {this.props.personalData ? (
            <Fragment>
              <div className="profile-fio-wrapper">
                <div className="profile-avatar">
                  <input
                    type="file"
                    type="file"
                    name="file"
                    id="file"
                    onChange={(event) =>
                      this.props.uploadAvatar(
                        this.props.firebase,
                        this.props.user.uid,
                        event.target.files[0]
                      )
                    }
                    className="profile-avatar-input"
                  />
                  <label for="file" className="profile-avatar-label">
                    <CameraOutlined />
                  </label>
                  {this.props.personalData.avatar ? (
                    <img
                      src={this.props.personalData.avatar}
                      className="profile-avatar-img"
                    />
                  ) : (
                    <UserOutlined className="profile-avatar-icon" />
                  )}
                </div>
                <div>
                  <Text className="profile-header">
                    {this.props.personalData.fio}
                  </Text>
                  <Text className="profile-subheader">
                    {this.props.personalData.moderator
                      ? "Администратор"
                      : "Пользователь"}
                  </Text>
                </div>
              </div>
              <div className="profile-buttons">
                <div
                  className={
                    this.state.selectedBlock === "info"
                      ? "profile-button-active " + "profile-button"
                      : "profile-button"
                  }
                  onClick={() => this.setState({ selectedBlock: "info" })}
                >
                  <ProfileOutlined className="profile-button-icon" />
                  Информация
                </div>
                <div
                  className={
                    this.state.selectedBlock === "history"
                      ? "profile-button-active " + "profile-button"
                      : "profile-button"
                  }
                  onClick={() => this.setState({ selectedBlock: "history" })}
                >
                  <HistoryOutlined className="profile-button-icon" />
                  Проекты
                </div>
              </div>
            </Fragment>
          ) : (
            <Skeleton />
          )}
        </div>
        {this.state.selectedBlock === "info" ? (
          <Personal customUid={this.props.customUid} />
        ) : (
          <History customUid={this.props.customUid} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  personalData: state.personalData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (firebase, userId, flag) => dispatch(getUserData(firebase, userId, flag)),
    uploadAvatar: (firebase, userId, image) =>
      dispatch(uploadAvatar(firebase, userId, image)),
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
