import moment from "moment";
import XLSX from "xlsx";

const transformToValidDate = (str) => {
  return str
    .split(".")
    .map((part) => {
      return part.length < 2 ? "0" + part : part;
    })
    .join("-");
};

const getDataForHRCsv = (firebase, month) => {
  return new Promise((resolve) =>
    firebase.firestore
      .collection("users")
      .get()
      .then((querySnapshot) => {
        const getAllUsers = [];
        querySnapshot.forEach((doc) => {
          getAllUsers.push(
            firebase.firestore
              .collection("users")
              .doc(doc.id)
              .get()
              .then((res) => ({
                userDoc: res,
                id: doc.id,
                fio: doc.data().fio,
                position: doc.data().position
                  ? doc.data().position
                  : "Не задана",
              }))
          );
        });
        Promise.all(getAllUsers).then((docs) => {
          const offworkArr = [];
          const cardsArr = [];
          const getHistoryForUser = [];
          docs.forEach((doc) => {
            getHistoryForUser.push(
              firebase.firestore
                .collection("users")
                .doc(doc.userDoc.id)
                .collection("history")
                .get()
                .then((res) => ({ user: doc, history: res }))
            );
          });
          Promise.all(getHistoryForUser).then((aggregatedDoc) => {
            const getForm = [];
            aggregatedDoc.forEach((agrDoc) => {
              agrDoc.history.forEach((history) => {
                const dateToParse = moment(
                  transformToValidDate(history.id),
                  "DD-MM-YYYY"
                );
                const oneWeekForNextMonth = moment(month)
                  .add(1, "month")
                  .add(1, "weeks");
                if (
                  moment(dateToParse).isSame(month, "month") ||
                  (moment(dateToParse).isAfter(month) &&
                    moment(dateToParse).isBefore(oneWeekForNextMonth))
                ) {
                  getForm.push(
                    firebase.firestore
                      .collection("users")
                      .doc(agrDoc.user.id)
                      .collection("history")
                      .doc(history.id)
                      .collection("form")
                      .get()
                  );
                }
              });
            });
            Promise.all(getForm).then((formData) => {
              formData.forEach((form) => {
                const offwork = {};
                const card = {};
                form.forEach((frm) => {
                  if (frm.data().offworkReason) {
                    offwork.dateLeft = moment(
                      frm.data().offworkDateLeft,
                      '"DD-MM-YYYY"'
                    );
                    offwork.dateRight = moment(
                      frm.data().offworkDateRight,
                      '"DD-MM-YYYY"'
                    );
                    offwork.reason = frm.data().offworkReason;
                    offwork.userId = frm.data().userId;
                  }
                  card.dateLeft = moment(
                    frm.data().formDateLeft,
                    '"DD-MM-YYYY"'
                  );
                  card.dateRight = moment(
                    frm.data().formDateRight,
                    '"DD-MM-YYYY"'
                  );
                  card.userId = frm.data().userId;
                });
                if (Object.keys(offwork).length > 0) offworkArr.push(offwork);
                cardsArr.push(card);
              });
              resolve({ offworks: offworkArr, cards: cardsArr, users: docs });
            });
          });
        });
      })
  );
};

export const getCSVForHR = (firebase, month) => {
  return (dispatch) => {
    const toCSV = [];
    const namedRow = ["Имя", "Должность"];
    /*
      for (
        let m = moment(month.startOf("month"));
        m.isSame(month, "month");
        m.add(1, "days")
      ) {
        if (!(m.weekday() === 6 || m.weekday() === 0)) namedRow.push(m.date());
      }*/

    for (
      let m = moment(month.startOf("month"));
      m.isSame(month, "month");
      m.add(1, "days")
    ) {
      namedRow.push(m.date());
    }
    namedRow.push("Отработано часов");
    namedRow.push("Причина отсутствия");
    toCSV.push(namedRow);

    getDataForHRCsv(firebase, month).then((data) => {
      const translateReason = (reason) => {
        if (reason === "illness") return "ОТ";
        if (reason === "vacation") return "Б";
        if (reason === "nonPaid") return "ДО";
        if (reason === "remote") return "8Д";
        if (reason === "assignment") return "8К";
        if (!reason) return "Не указана";
      };
      data.users.forEach((user) => {
        const userRow = [user.fio, user.position];
        const reasons = new Set();
        let reasonString = "";
        let userHours = 0;
        const userOffworks = data.offworks.filter((offwork) => {
          return offwork.userId === user.id;
        });
        const userCards = data.cards.filter((card) => {
          return card.userId === user.id;
        });
        for (
          let m = moment(month.startOf("month"));
          m.isSame(month, "month");
          m.add(1, "days")
        ) {
          let whatToPush = "-";
          userCards.forEach((card) => {
            if (
              m.isBetween(card.dateLeft, card.dateRight) ||
              (m.isSame(card.dateLeft, "day") &&
                m.isSame(card.dateLeft, "month")) ||
              (m.isSame(card.dateRight, "day") &&
                m.isSame(card.dateRight, "month"))
            ) {
              whatToPush = "8";
            }
          });
          userOffworks.forEach((offwork) => {
            if (
              m.isBetween(offwork.dateLeft, offwork.dateRight) ||
              (m.isSame(offwork.dateLeft, "day") &&
                m.isSame(offwork.dateLeft, "month")) ||
              (m.isSame(offwork.dateRight, "day") &&
                m.isSame(offwork.dateRight, "month"))
            ) {
              whatToPush = translateReason(offwork.reason);
              reasons.add(translateReason(offwork.reason));
            }
          });
          if (!(m.weekday() === 6 || m.weekday() === 0)) {
            if (whatToPush == "8" || whatToPush == "8Д" || whatToPush == "8К")
              userHours += 8;
            userRow.push(whatToPush);
          } else userRow.push("В");
        }
        reasons.forEach((item) => {
          reasonString = reasonString + item + " ";
          reasons.delete(item);
        });
        userRow.push(userHours);
        userRow.push(reasonString);
        toCSV.push(userRow);
      });
      const filename = "HR-" + (month.month() + 1) + "-" + month.year();
      const book = XLSX.utils.book_new();
      const sheet = XLSX.utils.aoa_to_sheet(toCSV);
      XLSX.utils.book_append_sheet(book, sheet, "sheet1");
      XLSX.writeFile(book, `${filename}.xls`);
      /*
        let csvContent =
          "data:text/csv;charset=utf-8," +
          toCSV.map((e) => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "HR_Table" + month.month() + ".csv");
        document.body.appendChild(link);
  
        link.click();
        */
    });
  };
};

const downloadTimespentCSV = (forms, month) => {
  const toCSV = [];
  const namedRow = [
    "ФИО",
    "День",
    "Проект",
    "Процент",
    "Этап",
    "Подтвердил форму",
    "Переработка",
    "Отсутствие",
  ];
  toCSV.push(namedRow);
  const translateReason = (reason) => {
    if (reason === "illness") return "ОТ";
    if (reason === "vacation") return "Б";
    if (reason === "nonPaid") return "ДО";
    if (reason === "remote") return "8Д";
    if (reason === "assignment") return "8К";
    if (!reason) return "Не указана";
  };
  forms.forEach((f) => {
    f.forEach((form) => {
      let formRow = [];
      const formData = form.data();
      const dateRight = formData.formDateLeft;
      if (formData.formSubmition === "weekly") {
        let m = moment(dateRight, "DD-MM-YYYY").add(1, "days");
        [1, 2, 3, 4, 5].forEach(() => {
          formRow.push(formData.fio);
          formRow.push(m.format("DD-MM-YYYY"));
          formRow.push(formData.projectData.name);
          formRow.push(formData.value);
          formRow.push(formData.selectedStage);
          if (formData.approvedBy) {
            formRow.push(formData.approvedBy);
          } else {
            formRow.push("Нет Данных");
          }
          if (formData.overwork) {
            formRow.push(formData.overwork);
          } else {
            formRow.push("Нет Данных");
          }
          const offworkDateLeft = moment(
            formData.offworkDateLeft,
            "DD-MM-YYYY"
          );
          const offworkDateRight = moment(
            formData.offworkDateRight,
            "DD-MM-YYYY"
          );
          if (
            m.isBetween(offworkDateLeft, offworkDateRight) ||
            (m.isSame(offworkDateLeft, "day") &&
              m.isSame(offworkDateLeft, "month")) ||
            (m.isSame(offworkDateRight, "day") &&
              m.isSame(offworkDateRight, "month"))
          ) {
            formRow.push(translateReason(formData.offworkReason));
          } else {
            formRow.push("Присутствовал");
          }
          m.subtract(1, "days");
          toCSV.push(
            formRow.map((string) => (string === null ? "" : `\"${string}\"`))
          );
          formRow = [];
        });
      } else {
        let m = moment(dateRight, "DD-MM-YYYY");
        formRow.push(formData.fio);
        formRow.push(m.format("DD-MM-YYYY"));
        formRow.push(formData.projectData.name);
        formRow.push(formData.value);
        formRow.push(formData.selectedStage);
        if (formData.approvedBy) {
          formRow.push(formData.approvedBy);
        } else {
          formRow.push("Нет Данных");
        }
        if (formData.overwork) {
          formRow.push(formData.overwork);
        } else {
          formRow.push("Нет Данных");
        }
        const offworkDateLeft = moment(formData.offworkDateLeft, "DD-MM-YYYY");
        const offworkDateRight = moment(
          formData.offworkDateRight,
          "DD-MM-YYYY"
        );
        if (
          m.isBetween(offworkDateLeft, offworkDateRight) ||
          (m.isSame(offworkDateLeft, "day") &&
            m.isSame(offworkDateLeft, "month")) ||
          (m.isSame(offworkDateRight, "day") &&
            m.isSame(offworkDateRight, "month"))
        ) {
          formRow.push(translateReason(formData.offworkReason));
        } else {
          formRow.push("Присутствовал");
        }
        toCSV.push(
          formRow.map((string) => (string === null ? "" : `\"${string}\"`))
        );
      }
    });
  });
  const filename = "Timespent-" + month.month() + "-" + month.year();
  const book = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(toCSV);
  XLSX.utils.book_append_sheet(book, sheet, "sheet1");
  XLSX.writeFile(book, `${filename}.xls`);
  /*
    let csvContent =
      "data:text/csv;charset=utf-8," + toCSV.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Timespent" + ".csv");
    document.body.appendChild(link);
  
    link.click();
    */
};

export const downloadShortTimespent = (forms, month) => {
  let groupedData = [];
  const namedRow = [
    "ФИО",
    "Проект",
    "Количество рабочих дней",
    "Процент к итогу",
  ];

  const formsCount = {};
  forms.forEach((f) => {
    f.forEach((form) => {
      const formData = form.data();
      if (!(formData.fio in formsCount)) formsCount[formData.fio] = new Set();
    });
  });

  groupedData.push(namedRow);
  let data = [];
  forms.forEach((f) => {
    f.forEach((form) => {
      const formData = form.data();
      const dateRight = formData.formDateLeft;
      if (formData.formSubmition === "weekly") {
        let m = moment(dateRight, "DD-MM-YYYY").add(1, "days");
        [1, 2, 3, 4, 5].forEach(() => {
          const offworkDateLeft = moment(
            formData.offworkDateLeft,
            "DD-MM-YYYY"
          );
          const offworkDateRight = moment(
            formData.offworkDateRight,
            "DD-MM-YYYY"
          );
          if (
            m.isBetween(offworkDateLeft, offworkDateRight) ||
            (m.isSame(offworkDateLeft, "day") &&
              m.isSame(offworkDateLeft, "month")) ||
            (m.isSame(offworkDateRight, "day") &&
              m.isSame(offworkDateRight, "month"))
          ) {
            if (
              formData.offworkReason == "remote" ||
              formData.offworkReason == "assignment"
            ) {
              formsCount[formData.fio].add(m.format("DD-MM-YYYY"));
              data.push({
                fio: formData.fio,
                project: formData.projectData.name,
                value: formData.value,
              });
            }
          } else {
            formsCount[formData.fio].add(m.format("DD-MM-YYYY"));
            data.push({
              fio: formData.fio,
              project: formData.projectData.name,
              value: formData.value,
            });
          }
          m.subtract(1, "days");
        });
      } else {
        let m = moment(dateRight, "DD-MM-YYYY");
        const offworkDateLeft = moment(formData.offworkDateLeft, "DD-MM-YYYY");
        const offworkDateRight = moment(
          formData.offworkDateRight,
          "DD-MM-YYYY"
        );
        if (
          m.isBetween(offworkDateLeft, offworkDateRight) ||
          (m.isSame(offworkDateLeft, "day") &&
            m.isSame(offworkDateLeft, "month")) ||
          (m.isSame(offworkDateRight, "day") &&
            m.isSame(offworkDateRight, "month"))
        ) {
          if (
            formData.offworkReason == "remote" ||
            formData.offworkReason == "assignment"
          ) {
            formsCount[formData.fio].add(m.format("DD-MM-YYYY"));
            data.push({
              fio: formData.fio,
              project: formData.projectData.name,
              value: formData.value,
            });
          }
        } else {
          formsCount[formData.fio].add(m.format("DD-MM-YYYY"));
          data.push({
            fio: formData.fio,
            project: formData.projectData.name,
            value: formData.value,
          });
        }
      }
    });
  });

  //sort

  const compare = (a, b) => {
    if (a.fio < b.fio) {
      return -1;
    }
    if (a.fio > b.fio) {
      return 1;
    }
    return 0;
  };
  data.sort(compare);
  let lastFio = data[0].fio;
  let lastProject = data[0].project;
  let hoursOnProject = 0;
  let valueSum = 0;
  data.forEach((d) => {
    if (lastProject === d.project && lastFio === d.fio) {
      hoursOnProject += d.value / 100;
      valueSum += d.value;
    } else {
      groupedData.push([
        lastFio,
        lastProject,
        hoursOnProject,
        valueSum / formsCount[lastFio].size,
      ]);
      lastFio = d.fio;
      lastProject = d.project;
      hoursOnProject = d.value / 100;
      valueSum = d.value;
    }
  });

  const filename = "Timespent-" + month.month() + "-" + month.year();
  const book = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(groupedData);
  XLSX.utils.book_append_sheet(book, sheet, "sheet1");
  XLSX.writeFile(book, `${filename}.xls`);
};

export const getCSVForTimespent = (firebase, month) => {
  return (dispatch) => {
    firebase.firestore
      .collection("ProjectHistory")
      .get()
      .then((querySnapshot) => {
        const getHistories = [];
        querySnapshot.forEach((project) => {
          getHistories.push(
            firebase.firestore
              .collection("ProjectHistory")
              .doc(project.id)
              .collection("history")
              .get()
              .then((res) => ({ project, histories: res }))
          );
        });
        Promise.all(getHistories).then((argDocs) => {
          const getAllFormItems = [];
          argDocs.forEach((argDoc) => {
            argDoc.histories.forEach((hst) => {
              const dateToParse = moment(
                transformToValidDate(hst.id),
                "DD-MM-YYYY"
              );
              if (moment(dateToParse).isSame(month, "month"))
                getAllFormItems.push(
                  firebase.firestore
                    .collection("ProjectHistory")
                    .doc(argDoc.project.id)
                    .collection("history")
                    .doc(hst.id)
                    .collection("form")
                    .get()
                );
            });
          });
          Promise.all(getAllFormItems).then((forms) => {
            downloadTimespentCSV(forms, month);
          });
        });
      });
  };
};

export const getCSVForTimespentAllTime = (firebase) => {
  return (dispatch) => {
    firebase.firestore
      .collection("ProjectHistory")
      .get()
      .then((querySnapshot) => {
        const getHistories = [];
        querySnapshot.forEach((project) => {
          getHistories.push(
            firebase.firestore
              .collection("ProjectHistory")
              .doc(project.id)
              .collection("history")
              .get()
              .then((res) => ({ project, histories: res }))
          );
        });
        Promise.all(getHistories).then((argDocs) => {
          const getAllFormItems = [];
          argDocs.forEach((argDoc) => {
            argDoc.histories.forEach((hst) => {
              const dateToParse = moment(
                transformToValidDate(hst.id),
                "DD-MM-YYYY"
              );
              getAllFormItems.push(
                firebase.firestore
                  .collection("ProjectHistory")
                  .doc(argDoc.project.id)
                  .collection("history")
                  .doc(hst.id)
                  .collection("form")
                  .get()
              );
            });
          });
          Promise.all(getAllFormItems).then((forms) => {
            downloadTimespentCSV(forms);
          });
        });
      });
  };
};

export const getShortTimespent = (firebase, month) => {
  return (dispatch) => {
    firebase.firestore
      .collection("ProjectHistory")
      .get()
      .then((querySnapshot) => {
        const getHistories = [];
        querySnapshot.forEach((project) => {
          getHistories.push(
            firebase.firestore
              .collection("ProjectHistory")
              .doc(project.id)
              .collection("history")
              .get()
              .then((res) => ({ project, histories: res }))
          );
        });
        Promise.all(getHistories).then((argDocs) => {
          const getAllFormItems = [];
          argDocs.forEach((argDoc) => {
            argDoc.histories.forEach((hst) => {
              const dateToParse = moment(
                transformToValidDate(hst.id),
                "DD-MM-YYYY"
              );
              if (moment(dateToParse).isSame(month, "month"))
                getAllFormItems.push(
                  firebase.firestore
                    .collection("ProjectHistory")
                    .doc(argDoc.project.id)
                    .collection("history")
                    .doc(hst.id)
                    .collection("form")
                    .get()
                );
            });
          });
          Promise.all(getAllFormItems).then((forms) => {
            downloadShortTimespent(forms, month);
          });
        });
      });
  };
};
