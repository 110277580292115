import React from "react";
import { Form, Input, Button } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../../../firebase";
import "antd/dist/antd.css";
import { signIn } from "../../../redux/actions";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import "./Auth.css";
import Logo from "../../../logo.png";

class Login extends React.Component {
  state = { email: "", password: "" };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => {
          return (
            <div className="login-wrapper">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={(values) =>
                  this.props.signIn(firebase, values.email, values.password)
                }
              >
                <img src={Logo} className="login-logo"></img>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите адрес электронной почты!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Адрес электронной почты"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите пароль!",
                    },
                  ]}
                  className="login-password"
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Пароль"
                  />
                </Form.Item>
                <Form.Item className="login-password">
                  <Link
                    className="login-form-forgot login-text-blue"
                    to="/auth/reset"
                  >
                    Забыли пароль?
                  </Link>
                  <Link className="login-text-blue" to="/auth/register">
                    Зарегистрироваться
                  </Link>
                </Form.Item>
                {this.props.authError ? <p className="auth-error">{this.props.authError.message}</p> : null}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button login-button-blue"
                  >
                    Войти
                  </Button>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </FirebaseContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({ authError: state.authError });

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (firebase, email, password) =>
      dispatch(signIn(firebase, email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
