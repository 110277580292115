import React from "react";
import { Slider } from "antd";
import { compareArrays } from "../../../../utils/utils";

class CustomSlider extends React.Component {
  state = { sliderValue: [] };
  constructor(props) {
    super(props);
    const { selectedProjects } = this.props;
    const values = this.getValues(selectedProjects);
    this.setState({ sliderValue: values });
  }

  getValues = (selectedProjects) => {
    const defaultValues = selectedProjects.map((project, index) => {
      return (100 / selectedProjects.length) * index;
    });
    defaultValues.push(100);

    return defaultValues;
  };

  componentDidUpdate = (prevProps) => {
    const { selectedProjects } = this.props;
    const values = this.getValues(selectedProjects);
    if (
      prevProps.selectedProjects.length != this.props.selectedProjects.length
    ) {
      this.setState({ sliderValue: values });
    }
  };

  getTrackStyle = () => {
    const { selectedProjects } = this.props;
    const trackStyle = selectedProjects.map((project, index) => {
      return { backgroundColor: project.color, borderColor: "#fff" };
    });

    return trackStyle;
  };

  getHandleStyle = () => {
    const { selectedProjects } = this.props;
    if (selectedProjects.length === 0) return [{ visibility: "hidden" }];
    const handleStyle = [{ visibility: "hidden" }, ...this.getTrackStyle()];
    handleStyle[handleStyle.length - 1] = { visibility: "hidden" };

    return handleStyle;
  };

  getTooltipStyle = () => {
    const { selectedProjects } = this.props;
    return selectedProjects.length === 1 ? { display: "none" } : {};
  };

  handleChange = (values) => {
    if (values[0] !== 0) {
      values[0] = 0;
    }
    if (values[values.length - 1] !== 100) {
      values[values.length - 1] = 100;
    }
    this.setState({ sliderValue: values });
  };

  isDisabled = () => {
    const { selectedProjects } = this.props;
    if (selectedProjects.length === 0) {
      return true;
    }
    return false;
  };

  setMarks = () => {
    const marks = {};
    const tmp = [0];
    for (let i = 1; i < this.state.sliderValue.length; i++) {
      tmp.push(this.state.sliderValue[i] - this.state.sliderValue[i - 1]);
    }
    this.state.sliderValue.forEach((value, ind) => {
      Object.assign(marks, { [value]: Math.round(tmp[ind]) });
    });
    return marks;
  };

  render() {
    return (
      <Slider
        disabled={this.isDisabled()}
        range
        allowCross={false}
        className="weekcard-slider"
        trackStyle={this.getTrackStyle()}
        handleStyle={this.getHandleStyle()}
        onChange={(values) => this.handleChange(values)}
        value={this.state.sliderValue}
        marks={this.setMarks()}
      />
    );
  }
}

export default CustomSlider;
