import React from "react";
import YouTube from "react-youtube";
import { Typography, Divider, Button, Skeleton } from "antd";
import { connect } from "react-redux";
import { FirebaseContext } from "../../../../firebase";
import { getUserData, completeTutorial } from "../../../../redux/actions";
import { withFirebase } from "../../../../firebase";

const { Text, Title } = Typography;

class Tutorial extends React.Component {
  componentDidMount() {
    this.props.getUserData(this.props.firebase, this.props.user.uid, "owner");
  }

  userTutorial = () => {
    return (
      <div className="tutorial">
        <Title level={4} className="tutorial-header">
          Инструкция
        </Title>
        <Divider className="profile-divider" style={{ margin: "15px 0" }} />
        <Text className="tutorial-text">
          Эта платформа нужна для учета процентного соотношения затраты рабочего
          времени на различные проекты.
          <br></br>
          <br></br>
          Каждую неделю в четверг в 10:00 вам будет открываться доступ проектов
          с вашим участием.
          <br></br>
          <br></br>
          От вас требуется:
          <br></br>
          1) Отметить проекты и этапы внутри проекта, над которыми вы работали в
          течение недели.
          <br></br>
          2) Приблизительно отметить, какой процент времени вы занимались каждым
          проектом в течение недели. Вся информация о ваших проектах будет
          храниться в личном кабинете.
        </Text>
        <Divider className="profile-divider" />
        <div>
          <Button
            type="primary"
            style={{ margin: "0 auto", display: "block" }}
            onClick={() =>
              this.props.completeTutorial(
                this.props.firebase,
                this.props.user.uid
              )
            }
          >
            Понятно
          </Button>
        </div>
      </div>
    );
  };

  moderatorTutorial = () => {
    return (
      <div className="tutorial">
        <Title level={4} className="tutorial-header">
          Инструкция
        </Title>
        <Divider className="profile-divider" style={{ margin: "15px 0" }} />
        <Text className="tutorial-text">
          Текст для админов появится позже
          <br></br>
        </Text>
        <Divider className="profile-divider" />
        <YouTube videoId="o3VRWAcUNtM" />
        <div>
          <Button
            type="primary"
            style={{ margin: "0 auto", display: "block" }}
            onClick={() =>
              this.props.completeTutorial(
                this.props.firebase,
                this.props.user.uid
              )
            }
          >
            Понятно
          </Button>
        </div>
      </div>
    );
  };

  renderTutorial = (moderator) => {
    return moderator ? this.moderatorTutorial() : this.userTutorial();
  };

  render() {
    return this.props.personalData ? (
      this.renderTutorial(this.props.personalData.moderator)
    ) : (
      <Skeleton />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    completeTutorial: (firebase, userId) =>
      dispatch(completeTutorial(firebase, userId)),
    getUserData: (firebase, userId, flag) =>
      dispatch(getUserData(firebase, userId, flag)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    personalData: state.personalData,
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(Tutorial)
);
