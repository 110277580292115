import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Topbar from "./Topbar";
import Inbox from "./Inbox/Inbox";
import Profile from "./Profile/Profile";
import Tutorial from "./Profile/Tutorial";
import "./Dashboard.css";
import "../Admin/Admin.css";
import ProjectController from "../Admin/ProjectController";
import Projects from "../Admin/Projects";
import Export from "../Admin/Export";
import Accept from "../Admin/Accept";

class Dashboard extends React.Component {
  state = {
    collapsed: false,
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const path = this.props.match.path;
    return (
      <div className="dashboard-background">
        <Topbar></Topbar>
        <Switch>
          <Route path={`${path}`} exact>
            <Inbox />
          </Route>
          <Route path={`${path}profile`}>
            <Profile />
          </Route>
          <Route path={`${path}tutorial`}>
            <Tutorial />
          </Route>
          <Route path={`${path}active`}>
            <Projects mode="active" />
          </Route>
          <Route path={`${path}finished`}>
            <Projects mode="finished" />
          </Route>
          <Route path={`${path}add`}>
            <ProjectController />
          </Route>
          <Route path={`${path}export`}>
            <Export />
          </Route>
          <Route path={`${path}accept`}>
            <Accept />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(Dashboard);
