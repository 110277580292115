import React from "react";
import { Button, DatePicker, Typography } from "antd";
import {
  getCSVForHR,
  getCSVForTimespent,
  getCSVForTimespentAllTime,
  getShortTimespent,
} from "../../../redux/export.js";
import { connect } from "react-redux";
import { withFirebase } from "../../../firebase";

const { Text, Title } = Typography;

class Export extends React.Component {
  state = {
    month: null,
  };
  render() {
    return (
      <div>
        <div className="export" style={{ textAlign: "center" }}>
          <Title level={5} style={{ fontSize: "16px" }}>
            Табель учета рабочего времени
          </Title>
          <DatePicker
            picker="month"
            placeholder="Выберите месяц"
            onChange={(dates) => this.setState({ month: dates })}
            style={{
              marginBottom: "20px",
              marginTop: "10px",
              width: "170px",
            }}
          />
          <div>
            <Button
              onClick={() =>
                this.props.getCSVForHR(this.props.firebase, this.state.month)
              }
              style={{ width: "170px" }}
            >
              Скачать
            </Button>
          </div>
          <Title level={4} style={{ marginTop: "50px", fontSize: "16px" }}>
            Данные о занятости на проектах
          </Title>
          <DatePicker
            picker="month"
            placeholder="Выберите месяц"
            onChange={(dates) => this.setState({ month: dates })}
            style={{
              marginBottom: "20px",
              marginTop: "10px",
              width: "170px",
            }}
          />
          <div>
            <Button
              onClick={() =>
                this.props.getCSVForTimespent(
                  this.props.firebase,
                  this.state.month
                )
              }
              style={{ width: "170px" }}
            >
              Скачать за месяц
            </Button>
          </div>
          <div>
            <Button
              onClick={() =>
                this.props.getCSVForTimespentAllTime(this.props.firebase)
              }
              style={{ marginTop: "20px", width: "170px" }}
            >
              Скачать за все время
            </Button>
          </div>
          <div>
            <Button
              onClick={() =>
                this.props.getShortTimespent(this.props.firebase, this.state.month)
              }
              style={{ marginTop: "20px", width: "170px" }}
            >
              Скачать сгрупированную таблицу
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCSVForHR: (firebase, month) => dispatch(getCSVForHR(firebase, month)),
    getCSVForTimespent: (firebase, month) =>
      dispatch(getCSVForTimespent(firebase, month)),
    getCSVForTimespentAllTime: (firebase) =>
      dispatch(getCSVForTimespentAllTime(firebase)),
    getShortTimespent: (firebase, month) =>
      dispatch(getShortTimespent(firebase, month)),
  };
};

export default withFirebase(connect(null, mapDispatchToProps)(Export));
