import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import React from "react";

//Prod Config

const config = {
  apiKey: "AIzaSyCxlqMtUegsgodH0yfx90VjKgeoDkaCmkE",
  authDomain: "timespent-2.firebaseapp.com",
  databaseURL: "https://timespent-2.firebaseio.com",
  projectId: "timespent-2",
  storageBucket: "timespent-2.appspot.com",
  messagingSenderId: "824760585998",
  appId: "1:824760585998:web:525b7d1b44ba5a0868515f",
};

//Test Config
/*
const config = {
  apiKey: "AIzaSyCOWF0baQEXd1OLJgFtmNDZK_m5Ik--vtU",
  authDomain: "timespent-test.firebaseapp.com",
  databaseURL: "https://timespent-test.firebaseio.com",
  projectId: "timespent-test",
  storageBucket: "timespent-test.appspot.com",
  messagingSenderId: "951620868306",
  appId: "1:951620868306:web:e1fccb7af08d4492f0f50b",
};
*/

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.firestore = app.firestore();
    this.storage = app.storage();
    this.firebaseApp = app;
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
}

const FirebaseContext = React.createContext(null);

export const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export { FirebaseContext };

export default Firebase;
