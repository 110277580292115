import React from "react";
import { Typography, Button, Divider, List, Skeleton } from "antd";
import { connect } from "react-redux";
import { UserOutlined, LeftOutlined } from "@ant-design/icons";
import { getProjects } from "../../../redux/actions";
import { withFirebase } from "../../../firebase/index";
import ProjectController from "./ProjectController";
import Profile from "../Dashboard/Profile/Profile";

const { Text, Title } = Typography;

class Projects extends React.Component {
  state = { selectedProjectName: "", view: "details", selectedUserId: "" };

  componentDidMount() {
    const isActive = this.props.mode === "active" ? true : false;
    this.props.getProjects(this.props.firebase, isActive);
  }

  componentDidUpdate(prevProps) {
    const isActive = this.props.mode === "active" ? true : false;

    if (this.props.mode != prevProps.mode) {
      this.setState({ selectedProjectName: "", selectedUserId: "" });
      this.props.getProjects(this.props.firebase, isActive);
    }
  }

  renderProjectEdit = () => (
    <ProjectController
      defaultEditData={this.props.projects.find(
        (project) => project.name === this.state.selectedProjectName
      )}
    />
  );

  renderProfileWithCustomUid = () => (
    <Profile customUid={this.state.selectedUserId} />
  );

  caseCount = (count, stem) => {
    if(count === 1)
      return `${count}   ${stem}`;
    if(count>1 && count<5)
      return `${count}   ${stem}а`;
    
    return `${count}   ${stem}ов`;
  }

  renderProjects = () => {
    if (this.props.fetching) {
      return (
        <div className="project-overview">
          <Skeleton style={{ margin: "100px auto" }} />
        </div>
      );
    } else {
      return (
        <div className="projects-wrapper">
          {this.props.projects &&
            this.props.projects.map((project) => {
              return (
                <div className="project-overview">
                  <Title level={4} className="project-overview-name">
                    {project.name}
                  </Title>
                  <Text className="project-overview-fio">
                    {`Администратор: ${project.creatorFio}`}
                  </Text>
                  <Text className="project-overview-description">
                    {project.description}
                  </Text>
                  <Divider></Divider>
                  <div
                    className="project-overview-info"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Text className="project-overview-stages">
                      {this.caseCount(project.stages.length, 'этап')}
                    </Text>
                    <Text className="project-overview-participants">
                      {this.caseCount(project.participants.length, 'участник')}
                    </Text>
                  </div>
                  <Button
                    className="project-overview-button"
                    type="default"
                    onClick={() =>
                      this.setState({ selectedProjectName: project.name })
                    }
                  >
                    Подробнее
                  </Button>
                </div>
              );
            })}
        </div>
      );
    }
  };

  renderProjectDetails = () => {
    const project = this.props.projects.find(
      (project) => project.name === this.state.selectedProjectName
    );
    return (
      <div className="project-overview">
        <a
          className="ant-dropdown-link projects-link"
          onClick={() => this.setState({ selectedProjectName: "" })}
        >
          <LeftOutlined />
          {"  Назад"}
        </a>
        <Title level={4} className="project-overview-name">
          {project.name}
        </Title>
        <Text className="project-overview-fio">
          {`Администратор: ${project.creatorFio}`}
        </Text>
        <Text className="project-overview-description">
          {project.description}
        </Text>
        <Divider orientation="left" className="projects-overview-divider">
          Этапы
        </Divider>
        <List
          bordered
          dataSource={project.stages}
          renderItem={(prj) => <List.Item>{prj.name}</List.Item>}
        ></List>
        <Divider orientation="left" className="projects-overview-divider">
          Участники
        </Divider>
        {project.participants.map((participant) => (
          <div
            className="project-participant"
            key={participant.id}
            onClick={() => this.setState({ selectedUserId: participant.id })}
          >
            <div className="project-participant-image">
              <UserOutlined className="project-participant-icon" />
            </div>
            <div>
              <div>{participant.fio}</div>
              <div>{participant.position}</div>
            </div>
          </div>
        ))}
        {this.props.mode === "active" && (
          <Button
            className="project-overview-button project-edit-button"
            type="default"
            onClick={() => this.setState({ view: "edit" })}
          >
            Редактировать
          </Button>
        )}
      </div>
    );
  };

  renderSwitch = () => {
    if (this.state.selectedProjectName) {
      if (this.state.view === "details") {
        if (this.state.selectedUserId) {
          return this.renderProfileWithCustomUid();
        }
        return this.renderProjectDetails();
      } else {
        return this.renderProjectEdit();
      }
    } else {
      return this.renderProjects();
    }
  };

  render() {
    return this.renderSwitch();
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    projects: state.projects,
    fetching: state.fetching,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: (firebase, active) => dispatch(getProjects(firebase, active)),
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(Projects)
);
