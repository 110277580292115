import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import { setAuthError } from "../../../redux/actions";

class Auth extends React.Component {
  componentDidMount() {
    this.props.history.listen(() => {
      this.props.setAuthError("");
    });
  }

  render() {
    const path = this.props.match.path;
    return (
      <Switch>
        <Route path={`${path}/register`}>
          <Register />
        </Route>
        <Route path={`${path}/reset`}>
          <Reset />
        </Route>
        <Route path={path}>
          <Login />
        </Route>
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthError: (user) => dispatch(setAuthError(user)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Auth));
