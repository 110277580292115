import React from "react";
import {
  Typography,
  Button,
  Form,
  Divider,
  Skeleton,
  Tooltip,
  InputNumber,
  AutoComplete,
} from "antd";
import { connect } from "react-redux";
import { withFirebase } from "../../../firebase";
import {
  UserOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  getUserByName,
  getUserWaiting,
  removeUserCards,
  approveWaiting,
} from "../../../redux/actions";
import { message } from "antd";

const { Text, Title } = Typography;

class Accept extends React.Component {
  state = {
    participant: null,
    searchValue: "",
  };

  onParticipantSearch = (value) => {
    this.props.getUserByName(this.props.firebase, value);
    this.setState({ searchValue: value });
  };

  onParticipantSelect = (value) => {
    this.props.removeUserCards();
    const participant = this.props.suggestions.filter(
      (suggestion) => suggestion.fio === value
    )[0];
    this.setState({ participant, searchValue: "" });
    this.props.getUserWaiting(this.props.firebase, participant.id);
  };

  removeParticipant = (value) => {
    this.props.removeUserCards();
    this.setState({ participant: null });
  };

  getTitile = (history) => {
    if (history.formSubmition === "weekly")
      return `Неделя ${history.formDateLeft} - ${history.formDateRight}`;
    else return `День ${history.formDateLeft} - ${history.formDateRight}`;
  };

  acceptCard = (val, ind) => {
    let cardToSubmit = this.props.userCards[ind].card;
    Object.keys(val).forEach((key) => {
      cardToSubmit[
        cardToSubmit.findIndex((prj) => prj.projectId + prj.timestamp == key)
      ].value = val[key];
    });
    cardToSubmit.forEach((card) => {
      card.approvedBy = this.props.personalData.fio;
    });
    if (cardToSubmit.reduce((acc, prj) => acc + prj.value, 0) == 100)
      this.props.approveWaiting(
        this.props.firebase,
        cardToSubmit,
        this.state.participant.id,
        ind
      );
    else message.error("Сумма значений должна быть равна 100");
  };

  render() {
    const fios =
      this.props.suggestions &&
      this.props.suggestions.map((suggestion) => ({
        value: suggestion.fio,
        position: suggestion.position ? suggestion.position : null,
        id: suggestion.id,
      }));

    return (
      <div style={{ overflow: "auto" }}>
        <div className="accept">
          <Title level={4} style={{ marginBottom: "15px" }}>
            Подтвердить данные
          </Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AutoComplete
              options={fios}
              onSearch={(val) => this.onParticipantSearch(val)}
              onSelect={(val) => this.onParticipantSelect(val)}
              value={this.state.searchValue}
              style={{ width: "100%" }}
            ></AutoComplete>
            <div style={{ margin: "0 10px" }}>
              <Tooltip title="Введите фамилию человека, данные которого нужно подтвердить">
                <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
              </Tooltip>
            </div>
          </div>
          {this.state.participant && (
            <div
              className="project-participant"
              style={{ marginBottom: "0" }}
              key={this.state.participant.id}
            >
              <div className="project-participant-image">
                <UserOutlined className="project-participant-icon" />
              </div>
              <div>
                <div>{this.state.participant.fio}</div>
                <div>{this.state.participant.position}</div>
              </div>
              <MinusCircleOutlined
                onClick={() =>
                  this.removeParticipant(this.state.participant.id)
                }
                className="project-participant-remove"
              />
            </div>
          )}
        </div>
        {this.props.fetching ? (
          <div className="profile-history">
            <Skeleton />
          </div>
        ) : (
          this.props.userCards &&
          this.props.userCards.map((hst, ind) => {
            const history = hst.card;
            return (
              <div className="profile-history">
                <Form onFinish={(val) => this.acceptCard(val, ind, history)}>
                  <Text>{this.getTitile(history[0])}</Text>
                  <Divider
                    className="profile-divider"
                    style={{ margin: "15px 0" }}
                  />
                  {history.map((project) => (
                    <div className="profile-history-project">
                      <Text className="profile-history-project-text">
                        {project.projectData.name}
                      </Text>
                      <Tooltip title={project.projectData.description}>
                        <InfoCircleOutlined />
                      </Tooltip>
                      <Text className="profile-history-stage">
                        {project.selectedStage}
                      </Text>
                      <Form.Item
                        initialValue={project.value}
                        noStyle
                        name={project.projectId + project.timestamp}
                      >
                        <InputNumber
                          style={{ width: "70px" }}
                          className="profile-history-value"
                        />
                      </Form.Item>
                    </div>
                  ))}
                  <Divider style={{ margin: "15px 0" }} />
                  {history[0].overwork && (
                    <div>
                      <Text>{`Переработки: ${history[0].overwork} часов`}</Text>
                      <Divider style={{ margin: "15px 0" }} />
                    </div>
                  )}
                  {history[0].offworkReason && (
                    <div>
                      <Text style={{ display: "block", marginTop: "15px" }}>
                        {history[0].offworkReason == "illness"
                          ? `Больничный: ${history[0].offworkDateLeft} - ${history[0].offworkDateRight}`
                          : `Отпуск: ${history[0].offworkDateLeft} - ${history[0].offworkDateRight}`}
                      </Text>
                      <Divider style={{ margin: "15px 0" }} />
                    </div>
                  )}
                  <Button htmlType="submit">Подтвердить</Button>
                </Form>
              </div>
            );
          })
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserByName: (firebase, fio) => dispatch(getUserByName(firebase, fio)),
    getUserWaiting: (firebase, userId) =>
      dispatch(getUserWaiting(firebase, userId)),
    removeUserCards: () => {
      dispatch(removeUserCards());
    },
    approveWaiting: (firebase, card, userId, cardIndex) =>
      dispatch(approveWaiting(firebase, card, userId, cardIndex)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    suggestions: state.suggestions,
    personalData: state.personalData,
    userCards: state.userCards,
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(Accept)
);
