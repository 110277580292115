import React from "react";
import { connect } from "react-redux";
import Dashboard from "./components/Page/Dashboard/Dashboard";
import Auth from "./components/Page/Auth/Auth";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./utils/history";
import { withFirebase } from "./firebase";
import { setUser } from "./redux/actions";

/*
import stages from "./stages.json";
import projects from "./projects.json";
*/

function PrivateRoute({ children, user, tutorial, fetching }) {
  return (
    <Route
      render={(props) => {
        if (user && user.uid) {
          if (history.location.pathname == "/auth") {
            return (
              <Redirect
                to={{
                  pathname: "/",
                }}
              />
            );
          }
          if (tutorial) {
            return (
              <Redirect
                to={{
                  pathname: "/tutorial",
                }}
              />
            );
          } else {
            return children;
          }
        } else {
          return (
            !fetching && (
              <Redirect
                to={{
                  pathname: "/auth",
                }}
              />
            )
          );
        }
      }}
    />
  );
}

class App extends React.Component {
  state = {
    fetching: true,
    user: null,
  };

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged((authUser) => {
      authUser ? this.props.setUser(authUser) : this.props.setUser(null);
      this.setState({ fetching: false });
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/auth">
            <Auth></Auth>
          </Route>
          <PrivateRoute
            user={this.props.user}
            fetching={this.state.fetching}
            path="/"
          >
            <Dashboard
              user={this.props.user}
              tutorial={this.props.tutorial}
            ></Dashboard>
          </PrivateRoute>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tutorial: state.tutorial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};

export default withFirebase(connect(mapStateToProps, mapDispatchToProps)(App));
