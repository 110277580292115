const initialState = { fetching: false, fetchingInbox: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SIGN_IN":
      return Object.assign({}, state, {
        user: { ...action.data.user },
      });
    case "REGISTER":
      return Object.assign({}, state, {
        user: { ...action.data.user },
      });
    case "SIGN_OUT":
      return Object.assign({}, state, {
        user: null,
        personalData: null,
        inbox: null,
        history: null,
        projects: null,
      });
    case "AUTH_ERROR":
      return Object.assign({}, state, {
        authError: action.data,
      });
    case "SET_USER":
      return Object.assign({}, state, {
        user: action.data,
      });
    case "GET_USER_DATA":
      if (action.data.flag === "owner") {
        return Object.assign({}, state, {
          headerData: action.data,
          personalData: action.data,
        });
      } else {
        return Object.assign({}, state, {
          personalData: action.data,
        });
      }
    case "UPDATE_PROFILE":
      return Object.assign({}, state, {
        personalData: { ...state.personalData, ...action.data },
      });
    case "UPLOAD_AVATAR":
      return Object.assign({}, state, {
        personalData: { ...state.personalData, avatar: action.data },
      });
    case "COMPLETE_TUTORIAL":
      return Object.assign({}, state, {
        tutorial: false,
      });
    case "GET_USER_BY_NAME": {
      return Object.assign({}, state, {
        suggestions: action.data,
      });
    }
    case "GET_PROJECT_SUGGESTIONS": {
      return Object.assign({}, state, {
        projectsSuggestions: action.data,
      });
    }
    case "GET_STAGE_SUGGESTIONS": {
      return Object.assign({}, state, {
        stagesSuggestions: action.data,
      });
    }
    case "GET_VALID_STAGES": {
      return Object.assign({}, state, {
        validStages: action.data,
      });
    }
    case "GET_WAITING": {
      return Object.assign({}, state, {
        userCards: action.data,
      });
    }
    case "REMOVE_USER_CARDS": {
      return Object.assign({}, state, {
        userCards: null,
      });
    }
    case "GET_INBOX": {
      const groupedInbox = {};
      action.data.forEach((element) => {
        if (groupedInbox[element.date])
          groupedInbox[element.date].push(element);
        else groupedInbox[element.date] = [element];
      });

      return Object.assign({}, state, {
        inbox: groupedInbox,
      });
    }
    case "POST_INBOX": {
      const inboxCopy = { ...state.inbox };
      const inboxWithDeletedElement = Object.fromEntries(
        Object.entries(inboxCopy).filter(([key, value]) => key !== action.data)
      );
      return Object.assign({}, state, {
        inbox: inboxWithDeletedElement,
      });
    }
    case "APPROVE_WAITING": {
      return Object.assign({}, state, {
        userCards: state.userCards.filter((el, index) => index !== action.data),
      });
    }
    case "GET_USER_HISTORY": {
      return Object.assign({}, state, {
        history: action.data,
      });
    }
    case "ADD_USER_TO_PROJECT": {
      return Object.assign({}, state, {
        userProjects: [...state.userProjects, action.data],
      });
    }
    case "DELETE_USER_FROM_PROJECT": {
      return Object.assign({}, state, {
        userProjects: state.userProjects.filter(
          (prj) => prj.id != action.data.id
        ),
      });
    }
    case "GET_USER_PROJECTS": {
      return Object.assign({}, state, {
        userProjects: action.data,
      });
    }
    case "GET_PROJECTS": {
      return Object.assign({}, state, {
        projects: action.data,
      });
    }
    case "FETCH_STARTED": {
      return Object.assign({}, state, {
        fetching: true,
      });
    }
    case "FETCH_COMPLETE": {
      return Object.assign({}, state, {
        fetching: false,
      });
    }
    case "FETCH_INBOX_STARTED": {
      return Object.assign({}, state, {
        fetchingInbox: true,
      });
    }
    case "FETCH_INBOX_COMPLETE": {
      return Object.assign({}, state, {
        fetchingInbox: false,
      });
    }
    default:
      return state;
  }
}
