import React from "react";
import { Button, Menu, Badge, Popover, Dropdown } from "antd";
import { connect } from "react-redux";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DownOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { logout, getUserData } from "../../../redux/actions.js";
import { Link } from "react-router-dom";
import { FirebaseContext } from "../../../firebase";
import { withFirebase } from "../../../firebase";
import LogoTopbar from "../../../img/LogoToolbar.png";

class Topbar extends React.Component {
  state = {
    collapsed: false,
  };
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount = () => {
    this.props.getUserData(this.props.firebase, this.props.user.uid, "owner");
  };

  logoutButton = (firebase) => (
    <div >
      <Button style={{ display: "block", width: "120px" }}>
        <Link to="/tutorial">Инструкция</Link>
      </Button>
      <Button style={{ display: "block", width: "120px", marginTop: '10px' }} onClick={() => this.props.logout(firebase)}>Выйти</Button>
    </div>
  );

  menu = (
    <Menu>
      <Menu.Item>
        <Link to="/active">Активные проекты</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/finished">Завершенные проеты</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/accept">Подтверждение данных</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/add">Добавить проект</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/export">Экспортировать данные</Link>
      </Menu.Item>
    </Menu>
  );

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => {
          return (
            <div className="topbar">
              {this.props.headerData && this.props.headerData.moderator && (
                <Dropdown overlay={this.menu}>
                  <Link
                    className="ant-dropdown-link toolbar-admin-menu"
                    onClick={(e) => e.preventDefault()}
                  >
                    <MenuOutlined className="toolbar-admin-menu-icon" />
                  </Link>
                </Dropdown>
              )}
              <Link to="/">
                <img src={LogoTopbar} className="topbar-logo" />
              </Link>
              <div className="toolbar-right">
                <Link to="/profile">
                  <div className="topbar-user-wrapper">
                    {this.props.headerData && this.props.headerData.avatar ? (
                      <img
                        src={this.props.headerData.avatar}
                        className="topbar-avatar"
                      />
                    ) : (
                      <UserOutlined className="topbar-user"></UserOutlined>
                    )}
                  </div>
                </Link>
                <Popover
                  content={() => this.logoutButton(firebase)}
                  className="toolbar-popover"
                >
                  <DownOutlined className="topbar-logout" />
                </Popover>
              </div>
            </div>
          );
        }}
      </FirebaseContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  headerData: state.headerData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (firebase) => dispatch(logout(firebase)),
    getUserData: (firebase, userId, flag) =>
      dispatch(getUserData(firebase, userId, flag)),
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(Topbar)
);
