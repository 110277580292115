import React from "react";
import { connect } from "react-redux";
import { Form, Input, Typography, Button, Skeleton, Select } from "antd";
import { UserOutlined, IdcardOutlined } from "@ant-design/icons";
import { withFirebase } from "../../../../firebase";
import { updateProfile } from "../../../../redux/actions";

const { Text } = Typography;

const { Option } = Select;

const prefixSelector = <span style={{ backgroundColor: "#fff" }}>+7</span>;

class Personal extends React.Component {
  state = { mode: "info" };

  renderInfo = () => {
    const Info = () => (
      <div className="profile-infotext">
        <Text className="profile-text-bold">ФИО:</Text>
        <Text>{this.props.personalData.fio}</Text>
        <Text className="profile-text-bold">E-mail:</Text>
        <Text>{this.props.personalData.email}</Text>
        <Text className="profile-text-bold">Телефон:</Text>
        <Text>{this.props.personalData.phone}</Text>
        <Text className="profile-text-bold">Должность:</Text>
        <Text>
          {this.props.personalData.position
            ? this.props.personalData.position
            : "Не задана"}
        </Text>
        <Text className="profile-text-bold">Отчет:</Text>
        <Text>
          {this.props.personalData.formSubmition === "weekly"
            ? "Каждую неделю"
            : "Каждый день"}
        </Text>
        {!this.props.customUid && (
          <Button onClick={() => this.setState({ mode: "edit" })}>
            Редактировать
          </Button>
        )}
      </div>
    );
    return this.props.personalData ? (
      Info()
    ) : (
      <div className="profile-infotext">
        <Skeleton />
      </div>
    );
  };

  editFormSubmit = (formValues) => {
    this.props.updateProfile(
      this.props.firebase,
      this.props.user.uid,
      formValues
    );
    this.setState({ mode: "info" });
  };

  renderEdit = () => {
    return (
      <Form
        name="profile-edit"
        className="profile-edit"
        initialValues={{
          remember: true,
        }}
        onFinish={(formValues) => this.editFormSubmit(formValues)}
      >
        <Form.Item
          name="fio"
          validateTrigger="onBlur"
          initialValue={this.props.personalData.fio}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите Фамалию, Имя, Отчество!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="ФИО"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          validateTrigger="onBlur"
          initialValue={this.props.personalData.phone}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите номер телефона!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value.length === 10 && /^\d+$/.test(value)) {
                  return Promise.resolve();
                }

                return Promise.reject("Введите десять цифр номера телефона");
              },
            }),
          ]}
        >
          <Input addonBefore={prefixSelector} placeholder="Телефон" />
        </Form.Item>
        <Form.Item
          name="position"
          validateTrigger="onBlur"
          initialValue={this.props.personalData.position}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите вашу должность",
            },
          ]}
        >
          <Input
            prefix={<IdcardOutlined className="site-form-item-icon" />}
            placeholder="Должность"
          />
        </Form.Item>
        <Form.Item
          name="formSubmition"
          initialValue={this.props.personalData.formSubmition}
        >
          <Select initialValue={this.props.personalData.formSubmition}>
            <Option value="weekly">Каждую неделю</Option>
            <Option value="daily">Каждый день</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button login-button-blue"
          >
            Готово
          </Button>
        </Form.Item>
      </Form>
    );
  };

  render() {
    return (
      <div className="profile-info">
        {this.state.mode == "edit" ? this.renderEdit() : this.renderInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  personalData: state.personalData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (firebase, userId, formValues) =>
      dispatch(updateProfile(firebase, userId, formValues)),
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(Personal)
);

