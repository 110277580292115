//Auth Actions
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const REGISTER = "REGISTER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const AUTH_ERROR = "AUTH_ERROR";

//User Actions
export const SET_USER = "SET_USER";
export const COMPLETE_TUTORIAL = "COMPLETE_TUTORIAL";
export const GET_INBOX = "GET_INBOX";
export const POST_INBOX = "POST_INBOX";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_HISTORY = "GET_USER_HISTORY";
export const GET_USER_PROJECTS = "GET_USER_PROJECTS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const ADD_USER_TO_PROJECT = "ADD_USER_TO_PROJECT";
export const DELETE_USER_FROM_PROJECT = "DELETE_USER_FROM_PROJECT";

//Admin Actions
export const GET_USER_BY_NAME = "GET_USER_BY_NAME";
export const GET_PROJECT_SUGGESTIONS = "GET_PROJECT_SUGGESTIONS";
export const GET_STAGE_SUGGESTIONS = "GET_STAGE_SUGGESTIONS";
export const GET_VALID_STAGES = "GET_VALID_STAGES";
export const POST_PROJECT = "POST_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const UPDARE_PEOJECT = "UPDARE_PEOJECT";
export const FINISH_PROJECT = "FINISH_PROJECT";
export const GET_WAITING = "GET_WAITING";
export const REMOVE_USER_CARDS = "REMOVE_USER_CARDS";
export const APPROVE_WAITING = "APPROVE_WAITING";

//Fetching
export const FETCH_STARTED = "FETCH_STARTED";
export const FETCH_COMPLETE = "FETCH_COMPLETE";
export const FETCH_INBOX_STARTED = "FETCH_INBOX_STARTED";
export const FETCH_INBOX_COMPLETE = "FETCH_INBOX_COMPLETE";
