import {
  SIGN_IN,
  SIGN_OUT,
  REGISTER,
  FORGOT_PASSWORD,
  AUTH_ERROR,
  FETCH_STARTED,
  FETCH_INBOX_STARTED,
  FETCH_COMPLETE,
  FETCH_INBOX_COMPLETE,
  SET_USER,
  COMPLETE_TUTORIAL,
  GET_USER_DATA,
  GET_USER_PROJECTS,
  GET_USER_BY_NAME,
  UPDATE_PROFILE,
  UPLOAD_AVATAR,
  GET_INBOX,
  GET_USER_HISTORY,
  POST_INBOX,
  GET_PROJECTS,
  POST_PROJECT,
  UPDARE_PEOJECT,
  FINISH_PROJECT,
  GET_PROJECT_SUGGESTIONS,
  GET_STAGE_SUGGESTIONS,
  GET_VALID_STAGES,
  GET_WAITING,
  REMOVE_USER_CARDS,
  ADD_USER_TO_PROJECT,
  DELETE_USER_FROM_PROJECT,
} from "./constants";

import { message } from "antd";
import history from "../utils/history";
import { isEqual } from "underscore";
import { user } from "firebase-functions/lib/providers/auth";

const reponseToActionData = (actionType, response) => ({
  type: actionType,
  data: response,
});

export const signIn = (firebase, email, password) => {
  return (dispatch) => {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((res) => {
        dispatch(reponseToActionData(SIGN_IN, res));
        history.push("/");
      })
      .catch((error) => {
        dispatch(reponseToActionData(AUTH_ERROR, error));
      });
  };
};

export const signOut = (firebase) => {
  return (dispatch) => {
    firebase.doSignOut().then((res) => {
      dispatch(reponseToActionData(SIGN_OUT, res));
      history.push("/");
    });
  };
};

export const setAuthError = (error) => {
  return (dispatch) => {
    dispatch(reponseToActionData(AUTH_ERROR, error));
  };
};

export const register = (firebase, formValues) => {
  return (dispatch) => {
    firebase
      .doCreateUserWithEmailAndPassword(formValues.email, formValues.password)
      .then((res) => {
        dispatch(reponseToActionData(REGISTER, res));
        firebase.firestore
          .collection("users")
          .doc(res.user.uid)
          .set({
            fio: formValues.fio,
            email: formValues.email,
            phone: formValues.phone,
            tutorial: true,
            moderator: false,
            formSubmition: "weekly",
          })
          .then(() => {
            history.push("/tutorial");
          });
      })
      .catch((error) => {
        dispatch(reponseToActionData(AUTH_ERROR, error));
      });
  };
};

export const forgotPassword = (firebase, email) => {
  return (dispatch) => {
    firebase
      .doPasswordReset(email)
      .then((res) => {
        history.push("/");
      })
      .catch((error) => {
        dispatch(reponseToActionData(AUTH_ERROR, error));
      });
  };
};

export const setUser = (user) => {
  return (dispatch) => {
    dispatch(reponseToActionData(SET_USER, user));
    if (user && history.location.pathname === "/auth") {
      history.push("/");
    }
  };
};

export const logout = (firebase) => {
  return (dispatch) => {
    firebase.doSignOut().then((res) => {
      dispatch(reponseToActionData(SIGN_OUT, res));
      history.push("/");
    });
  };
};

export const getUserData = (firebase, userId, flag) => {
  return (dispatch) => {
    dispatch(reponseToActionData(FETCH_STARTED));
    firebase.firestore
      .collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        dispatch(
          reponseToActionData(GET_USER_DATA, {
            ...doc.data(),
            id: userId,
            flag,
          })
        );
        dispatch(reponseToActionData(FETCH_COMPLETE));
      });
  };
};

export const updateProfile = (firebase, userId, formValues) => {
  return (dispatch) => {
    firebase.firestore
      .collection("users")
      .doc(userId)
      .update({
        fio: formValues.fio,
        phone: formValues.phone,
        position: formValues.position,
        formSubmition: formValues.formSubmition,
      })
      .then(() => {
        dispatch(reponseToActionData(UPDATE_PROFILE, formValues));
        message.success("Данные профиля успешно изменены");
      })
      .catch((err) => message.error(err));
  };
};

export const completeTutorial = (firebase, userId) => {
  return (dispatch) => {
    firebase.firestore
      .collection("users")
      .doc(userId)
      .update({
        tutorial: false,
      })
      .then((res) => {
        dispatch(reponseToActionData(COMPLETE_TUTORIAL));
        history.push("/");
      });
  };
};

export const postProject = (firebase, project) => {
  return (dispatch) => {
    firebase.firestore
      .collection("projects")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        const projectData = [];
        querySnapshot.forEach((doc) =>
          projectData.push({ ...doc.data(), id: doc.id })
        );

        if (projectData.every((prj) => prj.name != project.name)) {
          firebase.firestore
            .collection("projects")
            .add({
              ...project,
              active: true,
            })
            .then((res) => {
              dispatch(reponseToActionData(POST_PROJECT));
              message.success("Проект успешно добавлен");
              history.push("/");
            })
            .catch((err) => message.error(err));
        } else {
          message.error("Проект с таким названием уже есть!");
        }
      });
  };
};

export const getUserByName = (firebase, fio) => {
  const prepareInput = fio
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return (dispatch) => {
    firebase.firestore
      .collection("users")
      .orderBy("fio", "asc")
      .startAt(prepareInput)
      .endAt(prepareInput + "\uf8ff")
      .limit(5)
      .get()
      .then((querySnapshot) => {
        const suggestions = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(reponseToActionData(GET_USER_BY_NAME, suggestions));
      });
  };
};

export const getProjectSuggestions = (firebase, searchStirng) => {
  return (dispatch) => {
    firebase.firestore
      .collection("ProjectNames")
      .orderBy("name")
      .startAt(searchStirng)
      .endAt(searchStirng + "\uf8ff")
      .limit(4)
      .get()
      .then((querySnapshot) => {
        const suggestions = querySnapshot.docs.map((doc) => doc.data().name);
        dispatch(reponseToActionData(GET_PROJECT_SUGGESTIONS, suggestions));
      });
  };
};

export const getActiveProjectSuggestions = (firebase, searchStirng) => {
  return (dispatch) => {
    firebase.firestore
      .collection("projects")
      .orderBy("name")
      .startAt(searchStirng)
      .endAt(searchStirng + "\uf8ff")
      .limit(4)
      .get()
      .then((querySnapshot) => {
        const uniqueSuggestions = new Set();
        querySnapshot.docs.forEach((doc) => {
          if (doc.data().active) uniqueSuggestions.add(doc.data().name);
        });
        dispatch(
          reponseToActionData(
            GET_PROJECT_SUGGESTIONS,
            Array.from(uniqueSuggestions)
          )
        );
      });
  };
};

export const getStageSuggestions = (firebase) => {
  return (dispatch) => {
    firebase.firestore
      .collection("StagesNames")
      .orderBy("name")
      .get()
      .then((querySnapshot) => {
        const suggestions = querySnapshot.docs.map((doc) => doc.data().name);
        dispatch(reponseToActionData(GET_STAGE_SUGGESTIONS, suggestions));
      });
  };
};

export const getValidStages = (firebase) => {
  return (dispatch) => {
    firebase.firestore
      .collection("StagesNames")
      .get()
      .then((querySnapshot) => {
        const stages = querySnapshot.docs.map((doc) => doc.data().name);
        dispatch(reponseToActionData(GET_VALID_STAGES, stages));
      });
  };
};

export const getInboxProjects = (firebase, uid) => {
  return (dispatch) => {
    dispatch(reponseToActionData(FETCH_INBOX_STARTED));
    firebase.firestore
      .collection("users")
      .doc(uid)
      .collection("inbox")
      .get()
      .then((querySnapshot) => {
        const getForm = [];
        querySnapshot.forEach((inbox) => {
          getForm.push(
            firebase.firestore
              .collection("users")
              .doc(uid)
              .collection("inbox")
              .doc(inbox.id)
              .collection("form")
              .get()
          );
        });
        Promise.all(getForm)
          .then((form) => {
            if (form.length === 0) {
              dispatch(reponseToActionData(FETCH_INBOX_COMPLETE));
            }
            const uniqueProjectsIds = [];
            const inbox = [];
            form.forEach((formProjects) => {
              formProjects.forEach((project) => {
                if (
                  !uniqueProjectsIds.find(
                    (el) => el === project.data().projectId
                  )
                )
                  uniqueProjectsIds.push(project.data().projectId);
                const projectsPromises = uniqueProjectsIds.map((id) =>
                  firebase.firestore.collection("projects").doc(id).get()
                );
                Promise.all(projectsPromises).then((projects) => {
                  const projectsData = [];
                  projects.forEach((prj) =>
                    projectsData.push({ ...prj.data(), id: prj.id })
                  );
                  inbox.push({
                    ...project.data(),
                    projectData: projectsData.filter(
                      (p) => p.id === project.data().projectId
                    )[0],
                  });
                  dispatch(reponseToActionData(GET_INBOX, inbox));
                  dispatch(reponseToActionData(FETCH_INBOX_COMPLETE));
                });
              });
            });
          })
          .catch();
      });
  };
};

export const postToWaiting = (firebase, card, uid, objKey) => {
  return (dispatch) => {
    firebase.firestore
      .collection("users")
      .doc(uid)
      .collection("waiting")
      .doc(card[0].date)
      .set({
        card,
      })
      .then(() => {
        const deleteInbox = card.map((el) => {
          firebase.firestore
            .collection("users")
            .doc(uid)
            .collection("inbox")
            .doc(el.date)
            .collection("form")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((prj) => {
                firebase.firestore
                  .collection("users")
                  .doc(uid)
                  .collection("inbox")
                  .doc(el.date)
                  .collection("form")
                  .doc(prj.id)
                  .delete();
              });
            })
            .then(() => {
              firebase.firestore
                .collection("users")
                .doc(uid)
                .collection("inbox")
                .doc(el.date)
                .delete();
            });
        });
        Promise.all(deleteInbox).then(() => {
          dispatch(reponseToActionData(POST_INBOX, objKey));
          message.success("Отчет успешно отправлен");
        });
      });
  };
};

export const getUserWaiting = (firebase, uid) => {
  return (dispatch) => {
    firebase.firestore
      .collection("users")
      .doc(uid)
      .collection("waiting")
      .get()
      .then((querySnapshot) => {
        const getAllWaiting = [];
        querySnapshot.forEach((doc) =>
          getAllWaiting.push(
            firebase.firestore
              .collection("users")
              .doc(uid)
              .collection("waiting")
              .doc(doc.id)
              .get()
          )
        );
        Promise.all(getAllWaiting).then((cardDocs) => {
          const cards = cardDocs.map((doc) => doc.data());
          dispatch(reponseToActionData(GET_WAITING, cards));
        });
      });
  };
};

export const approveWaiting = (firebase, card, uid, cardIndex) => {
  return (dispatch) => {
    firebase.firestore
      .collection("users")
      .doc(uid)
      .collection("history")
      .doc(card[0].date)
      .set({
        formDateLeft: card[0].formDateLeft,
        formDateRight: card[0].formDateRight,
      })
      .then(() => {
        const setForm = card.map((el) => {
          firebase.firestore
            .collection("users")
            .doc(uid)
            .collection("history")
            .doc(card[0].date)
            .collection("form")
            .add({
              ...el,
            });
        });

        Promise.all(setForm).then(() => {
          const setHistoryDoc = card.map((el) => {
            firebase.firestore
              .collection("ProjectHistory")
              .doc(el.projectData.id)
              .set({
                id: el.projectData.id,
                active: el.projectData.active,
              });
          });
          Promise.all(setHistoryDoc).then(() => {
            const setHistory = card.map((el) => {
              firebase.firestore
                .collection("ProjectHistory")
                .doc(el.projectData.id)
                .collection("history")
                .doc(el.date)
                .set({
                  formDateLeft: card[0].formDateLeft,
                  formDateRight: card[0].formDateRight,
                })
                .then(() => {
                  firebase.firestore
                    .collection("ProjectHistory")
                    .doc(el.projectData.id)
                    .collection("history")
                    .doc(el.date)
                    .collection("form")
                    .add({
                      ...el,
                    });
                });
            });
            Promise.all(setHistory).then(() => {
              firebase.firestore
                .collection("users")
                .doc(uid)
                .collection("waiting")
                .doc(card[0].date)
                .delete()
                .then(() => {
                  dispatch(reponseToActionData("APPROVE_WAITING", cardIndex));
                  message.success("Отчет успешно подтвержден!");
                });
            });
          });
        });
      })
      .catch((err) => message.error(err));
  };
};

export const getUserHistory = (firebase, uid) => {
  return (dispatch) => {
    dispatch(reponseToActionData(FETCH_STARTED));
    firebase.firestore
      .collection("users")
      .doc(uid)
      .collection("history")
      .get()
      .then((querySnapshot) => {
        const getForm = [];
        querySnapshot.forEach((history) => {
          const userHistory = {};
          getForm.push(
            firebase.firestore
              .collection("users")
              .doc(uid)
              .collection("history")
              .doc(history.id)
              .collection("form")
              .get()
              .then((formCollection) => {
                formCollection.forEach((formDoc) => {
                  if (userHistory[history.id])
                    userHistory[history.id] = [
                      ...userHistory[history.id],
                      formDoc.data(),
                    ];
                  else userHistory[history.id] = [formDoc.data()];
                });
                return userHistory;
              })
          );
        });
        Promise.all(getForm).then((userHistories) => {
          let aggregatedHistory = {};
          userHistories.forEach((history) => {
            aggregatedHistory = Object.assign(aggregatedHistory, history);
          });
          dispatch(reponseToActionData(GET_USER_HISTORY, aggregatedHistory));
          dispatch(reponseToActionData(FETCH_COMPLETE));
        });
      });
  };
};

export const getProjects = (firebase, active) => {
  return (dispatch) => {
    dispatch(reponseToActionData(FETCH_STARTED));
    firebase.firestore
      .collection("projects")
      .where("active", "==", active)
      .get()
      .then((querySnapshot) => {
        const projectData = [];
        querySnapshot.forEach((doc) =>
          projectData.push({ ...doc.data(), id: doc.id })
        );
        dispatch(reponseToActionData(GET_PROJECTS, projectData));
        dispatch(reponseToActionData(FETCH_COMPLETE));
      });
  };
};

export const updateProject = (firebase, projectData, projectId) => {
  return (dispatch) => {
    firebase.firestore
      .collection("projects")
      .doc(projectId)
      .update({ ...projectData })
      .then(() => {
        dispatch(reponseToActionData(UPDARE_PEOJECT, projectData));
        message.success("Проект успешно отредактирован");
        history.push("/");
      })
      .catch((err) => message.error(err));
  };
};

export const finishProject = (firebase, projectId) => {
  return (dispatch) => {
    firebase.firestore
      .collection("projects")
      .doc(projectId)
      .update({ active: false })
      .then(() => {
        dispatch(reponseToActionData(FINISH_PROJECT, projectId));
        message.success("Проект успешно перенесен в завершенные");
        history.push("/");
      })
      .catch((err) => message.error(err));
  };
};

export const removeUserCards = () => {
  return (dispatch) => {
    dispatch(reponseToActionData(REMOVE_USER_CARDS));
  };
};

export const uploadAvatar = (firebase, userId, file) => {
  return (dispatch) => {
    firebase.storage
      .ref()
      .child(`avatars/${userId}`)
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          firebase.firestore
            .collection("users")
            .doc(userId)
            .update({ avatar: downloadURL })
            .then(() => {
              dispatch(reponseToActionData(UPLOAD_AVATAR, downloadURL));
              message.success("Фото профиля успешно изменено");
            });
        });
      })
      .catch((err) => message.error(err));
  };
};

export const getUserProjects = (firebase, userId) => {
  return (dispatch) => {
    dispatch(reponseToActionData(FETCH_STARTED));
    const userProjects = [];
    firebase.firestore
      .collection("projects")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        const projectData = [];
        querySnapshot.forEach((doc) =>
          projectData.push({ ...doc.data(), id: doc.id })
        );
        projectData.map((prj) => {
          prj.participants.map((part) => {
            if (part.id === userId) {
              userProjects.push(prj);
            }
          });
        });
        dispatch(reponseToActionData(GET_USER_PROJECTS, userProjects));
        dispatch(reponseToActionData(FETCH_COMPLETE));
      });
  };
};

export const addUserToProject = (firebase, userId, projectName) => {
  return (dispatch) => {
    firebase.firestore
      .collection("projects")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        const projectData = [];
        querySnapshot.forEach((doc) =>
          projectData.push({ ...doc.data(), id: doc.id })
        );
        const beforeUpdate = projectData.filter(
          (project) => project.name === projectName
        )[0];
        let participants = beforeUpdate.participants;
        firebase.firestore
          .collection("users")
          .doc(userId)
          .get()
          .then((doc) => {
            if (participants.every((participant) => participant.id != userId)) {
              participants.push(Object.assign({}, doc.data(), { id: userId }));
              const afterUpdate = Object.assign({}, beforeUpdate, {
                participants,
              });
              firebase.firestore
                .collection("projects")
                .doc(beforeUpdate.id)
                .set(afterUpdate)
                .then(() => {
                  message.success("Вы успешно добавлены на проект!");
                  dispatch(
                    reponseToActionData(ADD_USER_TO_PROJECT, afterUpdate)
                  );
                });
            } else {
              message.error("Вы уже участвуете в этом проекте!");
            }
          });
      });
  };
};

export const deleteUserFromProject = (firebase, userId, project) => {
  return (dispatch) => {
    firebase.firestore
      .collection("projects")
      .doc(project.id)
      .get()
      .then((doc) => {
        if (
          isEqual(Object.assign({}, doc.data(), { id: project.id }), project)
        ) {
          const participantsWithoutUser = project.participants.filter(
            (part) => part.id != userId
          );
          if (participantsWithoutUser.length === 0) {
            message.error("Нельзя покинуть проект, если вы последний участник");
          } else {
            firebase.firestore
              .collection("projects")
              .doc(project.id)
              .set(
                Object.assign({}, project, {
                  participants: participantsWithoutUser,
                })
              )
              .then(() => {
                message.success("Вы успешно удалены с проекта!");
                dispatch(
                  reponseToActionData(DELETE_USER_FROM_PROJECT, project)
                );
              });
          }
        } else {
          message.error(
            "Данные устарели. Обновите страницу и попробуйте снова."
          );
        }
      });
  };
};
