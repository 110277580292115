import React from "react";
import { connect } from "react-redux";
import {
  Typography,
  Pagination,
  Divider,
  Tooltip,
  Skeleton,
  List,
  AutoComplete,
  Button,
} from "antd";
import { InfoCircleOutlined, MinusCircleFilled } from "@ant-design/icons";
import { withFirebase } from "../../../../firebase/index";
import {
  getUserHistory,
  getUserProjects,
  getActiveProjectSuggestions,
  addUserToProject,
  deleteUserFromProject,
} from "../../../../redux/actions";
import { flatten } from "underscore";

const { Text, Title } = Typography;

const projects = ["1", "2", "3", "4", "5"];

class History extends React.Component {
  state = { minValue: 0, maxValue: 3, selectedProject: null };

  numEachPage = 3;

  reasonToText = (reason) => {
    switch (reason) {
      case "vacation":
        return "Ежегодный Отпуск";
      case "illness":
        return "Больничный";
      case "nonPaid":
        return "Отпуск без сохранения ЗП";
      case "remote":
        return "Дистанционная работа";
      default:
        return "Неизвестная причина";
    }
  };

  handlePagination = (value) => {
    this.setState({
      minValue: (value - 1) * this.numEachPage,
      maxValue: value * this.numEachPage,
    });
  };

  componentDidMount() {
    if (this.props.customUid)
      this.props.getUserHistory(this.props.firebase, this.props.customUid);
    else {
      this.props.getUserHistory(this.props.firebase, this.props.user.uid);
      this.props.getUserProjects(this.props.firebase, this.props.user.uid);
    }
  }

  getTitile = (history) => {
    if (history.formSubmition === "weekly")
      return `Неделя ${history.formDateLeft} - ${history.formDateRight}`;
    else return `День ${history.formDateLeft} - ${history.formDateRight}`;
  };

  onProjectSearch = (firebase, value) => {
    value && this.props.getActiveProjectSuggestions(firebase, value);
  };

  onProjectSelect = (value) => {
    const selectedProject = this.props.projectsSuggestions.filter(
      (suggestion) => suggestion === value
    )[0];
    this.setState({ selectedProject });
  };

  addProject = (event) => {
    event.preventDefault();
    this.props.addUserToProject(
      this.props.firebase,
      this.props.user.userId,
      this.state.selectedProject
    );
  };

  render() {
    let projects =
      this.props.projectsSuggestions &&
      this.props.projectsSuggestions.map((suggestion) => ({
        value: suggestion,
      }));
    let historyToArray = [];
    if (this.props.history) {
      historyToArray = Object.keys(this.props.history).map((dateGroup) => {
        return this.props.history[dateGroup];
      });
      historyToArray.sort((history) => history.formDateRight);
    }
    return (
      <div>
        {this.props.fetching ? (
          <div className="profile-history">
            <Skeleton />
          </div>
        ) : (
          <div>
            <div className="profile-projects">
              <Text>Ваши проекты</Text>
              <Divider
                className="profile-divider"
                style={{ margin: "15px 0" }}
              />
              <List
                bordered
                dataSource={this.props.userProjects}
                renderItem={(item) => (
                  <List.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        color: "#FF3C47",
                      }}
                    >
                      <Text>{item.name}</Text>
                      <MinusCircleFilled
                        onClick={() =>
                          this.props.deleteUserFromProject(
                            this.props.firebase,
                            this.props.user.uid,
                            item
                          )
                        }
                        style={{ textAlign: "right" }}
                      />
                    </div>
                  </List.Item>
                )}
              />
              <Divider
                className="profile-divider"
                style={{ margin: "15px 0" }}
              />
              <Text style={{ display: "block", margin: "15px 0" }}>
                Добавить проект
              </Text>

              <AutoComplete
                name="project"
                placeholder="Введите корректную НГ проекта"
                options={projects}
                onSearch={(val) =>
                  this.onProjectSearch(this.props.firebase, val)
                }
                onSelect={(val) => this.onProjectSelect(val)}
                className="project-users-search"
                style={{ marginBottom: "20px" }}
              />
              {this.state.selectedProject && (
                <Button
                  className="profile-button-active"
                  onClick={() =>
                    this.props.addUserToProject(
                      this.props.firebase,
                      this.props.user.uid,
                      this.state.selectedProject
                    )
                  }
                  type="primary"
                  style={{ color: "#fff !important" }}
                >
                  Добавить себя на этот проект
                </Button>
              )}
            </div>
            {this.props.history &&
              historyToArray
                .slice(this.state.minValue, this.state.maxValue)
                .map((history) => {
                  return (
                    <div className="profile-history">
                      <Text>{this.getTitile(history[0])}</Text>
                      <Divider
                        className="profile-divider"
                        style={{ margin: "15px 0" }}
                      />
                      {history.map((project) => (
                        <div className="profile-history-project">
                          <Text className="profile-history-project-text">
                            {project.projectData.name}
                          </Text>
                          <Tooltip title={project.projectData.description}>
                            <InfoCircleOutlined />
                          </Tooltip>
                          <Text className="profile-history-stage">
                            {project.selectedStage}
                          </Text>
                          <Text className="profile-history-value">
                            {project.value.toFixed(1)}
                          </Text>
                        </div>
                      ))}
                      <Divider style={{ margin: "15px 0" }} />
                      {history[0].overwork && (
                        <div>
                          <Text>{`Переработки: ${history[0].overwork} часов`}</Text>
                        </div>
                      )}
                      {history[0].offworkReason && (
                        <div>
                          <Text style={{ display: "block", marginTop: "15px" }}>
                            {`${this.reasonToText(history[0].offworkReason)}: ${
                              history[0].offworkDateLeft
                            } - ${history[0].offworkDateRight}`}
                          </Text>
                        </div>
                      )}
                    </div>
                  );
                })}
            <Pagination
              defaultCurrent={1}
              total={
                this.props.history
                  ? Object.keys(this.props.history).length
                  : null
              }
              pageSize={this.numEachPage}
              onChange={this.handlePagination}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "50px",
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    history: state.history,
    fetching: state.fetching,
    userProjects: state.userProjects,
    projectsSuggestions: state.projectsSuggestions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserHistory: (firebase, uid) => dispatch(getUserHistory(firebase, uid)),
    getUserProjects: (firebase, uid) =>
      dispatch(getUserProjects(firebase, uid)),
    getActiveProjectSuggestions: (firebase, searchString) =>
      dispatch(getActiveProjectSuggestions(firebase, searchString)),
    addUserToProject: (firebase, userId, selectedProject) =>
      dispatch(addUserToProject(firebase, userId, selectedProject)),
    deleteUserFromProject: (firebase, userId, project) =>
      dispatch(deleteUserFromProject(firebase, userId, project)),
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(History)
);
