import React from "react";
import { Form, Input, Button, Select } from "antd";
import { connect } from "react-redux";
import { FirebaseContext } from "../../../firebase";
import { register } from "../../../redux/actions";
import { Link } from "react-router-dom";
import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import Logo from "../../../logo.png";

const { Option } = Select;

const prefixSelector = <span style={{ backgroundColor: "#fff" }}>+7</span>;

class Register extends React.Component {
  state = { email: "", password: "" };

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => {
          return (
            <div className="login-wrapper">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={(formValues) =>
                  this.props.register(firebase, formValues)
                }
              >
                <img src={Logo} className="login-logo"></img>
                <Form.Item
                  name="fio"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите Фамалию, Имя, Отчество!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="ФИО"
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите номер телефона!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length === 10 && /^\d+$/.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Введите десять цифр номера телефона!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input addonBefore={prefixSelector} placeholder="Телефон" />
                </Form.Item>
                <Form.Item
                  name="email"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      type: "email",
                      message:
                        "Пожалуйста, введите действующий адрес электронной почты!",
                    },
                    {
                      required: true,
                      message: "Пожалуйста, введите адрес электронной почты!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Адрес электронной почты"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите пароль!",
                    },

                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value.length > 7) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "Пароль должен быть 8 и более символов!"
                        );
                      }
                    }),
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Пароль"
                  />
                </Form.Item>
                <Form.Item
                  name="password_confirm"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите пароль!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject("Пароли должны совпадать!");
                      },
                    }),
                  ]}
                  className="login-password"
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Пароль ещё раз"
                  />
                </Form.Item>
                <Form.Item className="login-password">
                  <Link
                    className="login-form-forgot login-text-blue"
                    href=""
                    to="/auth/reset"
                  >
                    Забыли пароль?
                  </Link>
                  <Link href="" className="login-text-blue" to="/auth">
                    Войти
                  </Link>
                </Form.Item>
                {this.props.authError ? (
                  <p className="auth-error">{this.props.authError.message}</p>
                ) : null}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button login-button-blue"
                  >
                    Зарегистрироваться
                  </Button>
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </FirebaseContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({ authError: state.authError });

const mapDispatchToProps = (dispatch) => {
  return {
    register: (firebase, formValues) =>
      dispatch(register(firebase, formValues)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
