import React from "react";
import { connect } from "react-redux";
import {
  Typography,
  Button,
  Form,
  Input,
  AutoComplete,
  Popover,
  Select,
} from "antd";
import {
  UserOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { ChromePicker } from "react-color";
import { FirebaseContext, withFirebase } from "../../../firebase";

import {
  getUserByName,
  getUserData,
  getProjectSuggestions,
  getStageSuggestions,
  getValidStages,
  postProject,
  updateProject,
  finishProject,
} from "../../../redux/actions";
import "./Admin.css";

const { Text, Title } = Typography;
const { Option } = AutoComplete;

class ProjectController extends React.Component {
  state = {
    participants: [],
    stages: [],
    serachParticipantValue: "",
    error: "",
    projectColor: "#cccccc",
    colorPicker: false,
  };

  componentDidMount() {
    this.props.getUserData(this.props.firebase, this.props.user.uid, "owner");
    this.props.getValidStages(this.props.firebase);
    if (this.props.defaultEditData)
      this.setState({
        participants: this.props.defaultEditData.participants,
        projectColor: this.props.defaultEditData.color,
      });
    this.loadStages(this.props.firebase);
  }

  onParticipantSelect = (val) => {
    const participants = [...this.state.participants];
    const participant = this.props.suggestions.filter(
      (suggestion) => suggestion.fio === val
    );
    const checkParticipantEntry = (obj) => obj.id === participant[0].id;
    if (!participants.some(checkParticipantEntry)) {
      participants.push(...participant);
      this.setState({
        participants: participants,
        serachParticipantValue: "",
        error: "",
      });
    }
  };

  onParticipantSearch = (value, firebase) => {
    value && this.props.getUserByName(firebase, value);
    this.setState({ serachParticipantValue: value });
  };

  onProjectSearch = (firebase, value) => {
    value && this.props.getProjectSuggestions(firebase, value);
  };

  onStageSearch = (val) => {};

  loadStages = (firebase) => {
    this.props.getStageSuggestions(firebase);
  };

  onStageSelect = (val) => {
    const projectsSuggestions = [...this.state.projects];
    const suggestion = this.props.projectsSuggestions.filter(
      (sug) => sug === val
    );
    const checkParticipantEntry = (obj) => obj === suggestion[0];
    if (!projectsSuggestions.some(checkParticipantEntry)) {
      projectsSuggestions.push(...suggestion);
      this.setState({
        projects: projectsSuggestions,
        serachProjectValue: "",
        error: "",
      });
    }
  };

  removeParticipant = (id) => {
    const filteredParticipants = this.state.participants.filter(
      (participant) => participant.id !== id
    );
    this.setState({ participants: filteredParticipants });
  };

  onSubmit = (firebase, formValues) => {
    if (
      this.state.participants.length === 0 ||
      !(Array.isArray(formValues.stages) && formValues.stages.length)
    ) {
      this.setState({ error: "Пожалуйста, добавьте участников и этапы!" });
    } else {
      const stagesToDB = formValues.stages.map((stage, index) => ({
        name: stage,
        order: index + 1,
      }));
      const name = formValues.project;
      delete formValues.project;
      const projectData = {
        ...formValues,
        name,
        creatorId: this.props.personalData.id,
        creatorFio: this.props.personalData.fio,
        color: this.state.projectColor,
      };
      projectData.stages = stagesToDB;
      projectData.participants = this.state.participants;
      this.props.defaultEditData
        ? this.props.updateProject(
            firebase,
            projectData,
            this.props.defaultEditData.id
          )
        : this.props.postProject(firebase, projectData);
    }
  };

  renderColorPicker = () => (
    <div>
      <ChromePicker
        color={this.state.projectColor}
        onChangeComplete={(color) => this.setState({ projectColor: color.hex })}
      />
      <Button
        style={{ display: "block", margin: "0 auto", marginTop: "10px" }}
        type="primary"
        onClick={() => this.hideColorPicker()}
      >
        Ок
      </Button>
    </div>
  );

  hideColorPicker = () => {
    this.setState({
      colorPicker: false,
    });
  };

  handleColorPicker = (colorPicker) => {
    this.setState({ colorPicker });
  };

  render() {
    const fios =
      this.props.suggestions &&
      this.props.suggestions.map((suggestion) => ({
        value: suggestion.fio,
        position: suggestion.position ? suggestion.position : null,
        id: suggestion.id,
      }));
    let projects = [];
    projects =
      this.props.projectsSuggestions &&
      this.props.projectsSuggestions.map((suggestion) => ({
        value: suggestion,
      }));

    const stages =
      this.props.stagesSuggestions &&
      this.props.stagesSuggestions.map((suggestion) => ({
        value: suggestion,
      }));

    let defaultValues = Object.assign({}, this.props.defaultEditData);
    if (this.props.defaultEditData) {
      const stagesName = defaultValues.stages.map((stage) => stage.name);
      defaultValues.project = defaultValues.name;
      projects = [{ value: defaultValues.name }];
      defaultValues.stages = [...stagesName];
    } else {
      defaultValues.stages = ['Одностадийное проектирование'];
    }
    return (
      <FirebaseContext.Consumer>
        {(firebase) => {
          return (
            <div className="project-controller">
              <Form
                onFinish={(formValues) => this.onSubmit(firebase, formValues)}
                initialValues={defaultValues && defaultValues}
              >
                <Title level={4} className="project-add-title">
                  {this.props.defaultEditData
                    ? "Редактировать проект"
                    : "Добавить проект"}
                </Title>
                <Form.Item
                  name="project"
                  hasFeedback
                  rules={[
                    {
                      validator: (_, value = "") =>
                        value &&
                        projects &&
                        projects.some((sugg) => value === sugg.value)
                          ? Promise.resolve()
                          : Promise.reject(
                              "Сверьте НГ с ЕСП и введите корректное название"
                            ),
                    },
                  ]}
                >
                  <AutoComplete
                    name="project"
                    placeholder="Введите корректную НГ проекта"
                    options={projects}
                    onSearch={(val) => this.onProjectSearch(firebase, val)}
                    className="project-users-search"
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message:
                        "Добавьте краткое описание, например, разговорное название проекта",
                    },
                  ]}
                >
                  <Input placeholder="Добавьте описание или разговорное название проекта" />
                </Form.Item>
                <Form.List name="stages">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <Form.Item required={false} key={field.key}>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                ({ getFieldValue }) => {
                                  const validStages = this.props.validStages;
                                  return {
                                    validator(rule, value) {
                                      if (
                                        value &&
                                        validStages &&
                                        validStages.some(
                                          (valid) => valid === value
                                        ) &&
                                        new Set(getFieldValue("stages")).size ==
                                          getFieldValue("stages").length
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        "Каждый этап должен быть уникален"
                                      );
                                    },
                                  };
                                },
                              ]}
                              noStyle
                              className="project-stage"
                            >
                              <Select
                                placeholder="Выберите этап проекта из списка"
                                options={stages}
                                className="project-stage-input"
                              />
                            </Form.Item>

                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => {
                                remove(field.name);
                              }}
                              className="project-stage-remove"
                            />
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                              this.setState({ error: "" });
                            }}
                            className="project-add-stages"
                          >
                            <PlusOutlined /> Добавить этап
                          </Button>
                        </Form.Item>
                      </div>
                    );
                  }}
                </Form.List>
                <Popover
                  content={this.renderColorPicker}
                  title="Выберите цвет"
                  trigger="click"
                  visible={this.state.colorPicker}
                  onVisibleChange={this.handleColorPicker}
                >
                  <Button
                    style={{
                      color: this.state.projectColor,
                      borderColor: this.state.projectColor,
                      margin: "25px auto",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    Выберите цвет
                  </Button>
                </Popover>
                <Form.Item
                  validateStatus={
                    this.state.participants.length > 0
                      ? "success"
                      : "validating"
                  }
                >
                  <AutoComplete
                    name="paticipant"
                    placeholder="Добавить участников"
                    options={fios}
                    onSearch={(val) => this.onParticipantSearch(val, firebase)}
                    onSelect={(val) => this.onParticipantSelect(val)}
                    value={this.state.serachParticipantValue}
                    className="project-users-search"
                  />
                </Form.Item>

                {this.state.participants &&
                  this.state.participants.map((participant) => (
                    <div className="project-participant" key={participant.id}>
                      <div className="project-participant-image">
                        <UserOutlined className="project-participant-icon" />
                      </div>
                      <div>
                        <div>{participant.fio}</div>
                        <div>{participant.position}</div>
                      </div>
                      <MinusCircleOutlined
                        onClick={() => this.removeParticipant(participant.id)}
                        className="project-participant-remove"
                      />
                    </div>
                  ))}
                {this.state.error && (
                  <Text className="project-add-error">{this.state.error}</Text>
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="project-add-button project-add-edit-confirm"
                  >
                    {this.props.defaultEditData ? "Готово" : "Создать"}
                  </Button>
                  {this.props.defaultEditData && (
                    <Button
                      danger
                      onClick={() =>
                        this.props.finishProject(firebase, defaultValues.id)
                      }
                      style={{
                        display: "block",
                        margin: "0 auto",
                        marginTop: "20px",
                      }}
                    >
                      Завершить проект
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
          );
        }}
      </FirebaseContext.Consumer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserByName: (firebase, fio) => dispatch(getUserByName(firebase, fio)),
    postProject: (firebase, projectData) =>
      dispatch(postProject(firebase, projectData)),
    updateProject: (firebase, projectData, projectId) =>
      dispatch(updateProject(firebase, projectData, projectId)),
    finishProject: (firebase, projectId) =>
      dispatch(finishProject(firebase, projectId)),
    getUserData: (firebase, userId, flag) =>
      dispatch(getUserData(firebase, userId, flag)),
    getProjectSuggestions: (firebase, searchString) =>
      dispatch(getProjectSuggestions(firebase, searchString)),
    getStageSuggestions: (firebase) => dispatch(getStageSuggestions(firebase)),
    getValidStages: (firebase) => dispatch(getValidStages(firebase)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    stagesSuggestions: state.stagesSuggestions,
    projectsSuggestions: state.projectsSuggestions,
    suggestions: state.suggestions,
    personalData: state.personalData,
    validStages: state.validStages,
  };
};

export default withFirebase(
  connect(mapStateToProps, mapDispatchToProps)(ProjectController)
);
